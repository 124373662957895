import React from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';

export const ProtectedRoute = ({
  component: Component,
  token,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated || token) {
          return <Component {...rest} {...props} />;
        } else {
          return <Navigate to='/auth/login' />;
        }
      }}
    />
  );
};

export const ProtectAdmin = ({ Component, token, isAuthenticated, user }) => {
  const shouldRedirect = isAuthenticated || token ? true : false;
  return shouldRedirect ? <Component /> : <Navigate to='/auth/login' replace />;
};

export const RedirectHome = ({ Component, token, isAuthenticated, user }) => {
  const location = useLocation();
  return !token ? (
    <Component />
  ) : (
    <Navigate to='/' replace state={{ from: location }} />
  );
};
