import React from "react";
import "./index.css";
import Payment from "./pages/Payment/Payment";
import Add from "./pages/Referrer/Add";
import Transaction from "./pages/Transaction/Transaction";
import User from "./pages/User/User";
import { connect } from "react-redux";
import Loading from "./pages/Loading/Loading";
const Popup = ({
  state: { page_to_show },
  setState,
  userDetails,
  transactionDetails,
  paymentDetails,
  data: { server_message },
}) => {
  // console.log(user_details, page_to_show);
  return (
    <section className="popups-admin">
      {page_to_show === "user" ? (
        <User
          userDetails={userDetails}
          setGlobalState={setState}
          server_message={server_message}
        />
      ) : page_to_show === "transactiondetails" ? (
        <Transaction Tdetails={transactionDetails} setGlobalState={setState} />
      ) : page_to_show === "paymentdetails" ? (
        <Payment paymentDetails={paymentDetails} setGlobalState={setState} />
      ) : page_to_show === "add-referrer" ? (
        <Add setGlobalState={setState} server_message={server_message} />
      ) : (
        <Loading />
      )}
    </section>
  );
};

const mapStateToProps = ({ data }) => ({
  data,
});

export default connect(mapStateToProps, null)(Popup);
