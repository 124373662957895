/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./index.css";
import "./mobile.css";
import Utils from "../../../../../components/utils";
const RecentUsers = ({ setState, Users }) => {
  const [last, setLast] = useState();
  const setStatusBackground = (user) => {
    if (user.status === "online") {
      return { background: "#5df888" };
    } else if (user.status === "offline") {
      return { background: "#f85d5d" };
    }
  };

  const last_logged_users = [];

  useEffect(() => {
    const date = Date.now() - 1000000;
    Users.map((user) => {
      if (parseInt(user.last_login) > parseInt(date)) {
        last_logged_users.push(user);
      }

      return setLast(last_logged_users);
    });

  }, [Users]);



  const tableData = (user) => {
    return (
      <div key={user.id} className="table-data" onClick={() => Open(user)}>
        <ul>
          <li>{user.isAdmin ? "admin" : "user"}</li>
          <li>{user.name}</li>
          <li className="hide-on-mobile"> {user.email}</li>
          <li>
            {Utils.formatCurrency(
              user.wallet_balance ? parseInt(user.wallet_balance) : 0
            )}
          </li>
          <li style={setStatusBackground(user)} className="status">
            {user.status}
          </li>
        </ul>
      </div>
    );
  };

  const Open = (data) => {
    setState("page_to_Show", "user");
    setState("user_details", data);
    Utils.showAdminPopUp();
  };

  return (
    <div className="lastloggedusers">
      <h3>Users</h3>
      <div className="table">
        <div className="table-label">
          <ul>
            <li>Role</li>
            <li>Name</li>
            <li className="hide-on-mobile">Email</li>
            <li>Wallet Balance</li>

            <li>Status</li>
          </ul>
        </div>
        {last ? last.map((user) => tableData(user)) : null}
      </div>
    </div>
  );
};

export default RecentUsers;
