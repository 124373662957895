/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import utils from "../../../../components/utils";
import "./index.css";
import "./mobile.css";
import { connect } from "react-redux";
const Referrers = ({ setGlobalState, referers }) => {
  const [localState, setLocalState] = useState({
    filtered: [],
    searchByName: "",
    searchById: "",
  });

  const { filtered, searchById, searchByName } = localState;

  const modifyState = (key, value) => {
    setLocalState({ ...localState, [key]: value });
  };

  useEffect(() => {
    modifyState("filtered", referers);
  }, [referers]);

  useEffect(() => {
    if (!searchById) {
      if (searchByName) {
        let fill = referers.filter((x) =>
          x.name.toLowerCase().includes(searchByName.toLowerCase())
        );

        modifyState("filtered", fill);
      } else {
        modifyState("filtered", referers);
      }
    }
  }, [searchByName]);

  useEffect(() => {
    if (!searchByName) {
      if (searchById) {
        let fill = referers.filter((x) =>
          x.referer_id.toLowerCase().includes(searchById.toLowerCase())
        );
        modifyState("filtered", fill);
      } else {
        modifyState("filtered", referers);
      }
    }
  }, [searchById]);

  const table = (referrer) => {
    return (
      <div key={referrer._id} className="table-data">
        <ul>
          <li>{referrer.referer_id}</li>
          <li>{referrer.name}</li>
          <li>{referrer.description}</li>

          <li>{referrer.counts}</li>
        </ul>
      </div>
    );
  };

  const Open = () => {
    setGlobalState("page_to_show", "add-referrer");
    utils.showAdminPopUp();
  };

  return (
    <section className="referrer-view">
      <div className="header">
        <h3>Referrers</h3>

        <div>
          <input
            onChange={(e) => modifyState("searchByName", e.target.value)}
            type="text"
            placeholder="search by name"
          />
          <input
            onChange={(e) => modifyState("searchById", e.target.value)}
            type="text"
            placeholder="search by ID"
          />
        </div>
      </div>

      <div className="table">
        <div className="table-label">
          <ul>
            <li>Referrer ID</li>
            <li>Name</li>
            <li>Description</li>
            <li>Refferals</li>
          </ul>
        </div>

        {filtered ? filtered.map((x) => table(x)) : null}

        <div className="floating-add-button" onClick={() => Open()}>
          <i className="fa fa-plus"></i>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ data: { referers } }) => ({
  referers,
});

export default connect(mapStateToProps, null)(Referrers);
