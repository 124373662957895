/* eslint-disable import/no-anonymous-default-export */
// import { randomBytes } from "crypto";
export default {
  formatCurrency: (num) => {
    return ' ₦' + Number(num.toFixed(2)).toLocaleString() + ' ';
  },

  currency: (num) => {
    return Number(num.toFixed(2)).toLocaleString() + ' ';
  },

  showPopUp: () => {
    const pop = document.getElementsByClassName('popups');
    pop[0].classList.add('show-blurry-pop');
  },
  showAdminPopUp: () => {
    const pop = document.getElementsByClassName('popups-admin');
    pop[0].classList.add('show-blurry-pop');
  },

  closePopUp: () => {
    const pop = document.getElementsByClassName('popups');
    pop[0].classList.remove('show-blurry-pop');
  },
  closeAdminPopUp: () => {
    const pop = document.getElementsByClassName('popups-admin');
    pop[0].classList.remove('show-blurry-pop');
  },
  // generateId: () => {
  //   const id = randomBytes(10).toString("hex");
  //   return id;
  // },

  setTransactionStatusBackground: (transaction) => {
    if (transaction.status === 'failed') {
      return { background: '#f85d5d ' };
    } else if (transaction.status === 'reversed') {
      return { background: '#4a7111' };
    } else if (transaction.status === 'pending') {
      return {
        background: '#707471',
      };
    } else if (transaction.status === 'cancelled') {
      return {
        background: '#f85d5d',
      };
    } else {
      return { background: '#5df888' };
    }
  },
  setPaymentStatusBackground: (payment) => {
    if (payment.status === 'completed') {
      return {
        background: '#5df888',
      };
    } else if (payment.status === 'failed') {
      return {
        background: '#f85d5d',
      };
    } else if (payment.status === 'pending') {
      return {
        background: '#707471',
      };
    } else if (payment.status === 'cancelled') {
      return {
        background: '#f85d5d',
      };
    }
  },

  formatDate: (date) => {
    date = new Date(date).getTime();

    let hours = new Date(parseInt(date)).getHours() % 12; //To Get Local hours

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    let Nday = new Date(parseInt(date)).getDate();
    let day = days[new Date(parseInt(date)).getDay()];
    let month = months[new Date(parseInt(date)).getMonth()];
    let year = new Date(parseInt(date)).getFullYear();

    if (Nday < 10) {
      Nday = '0' + Nday;
    }
    if (hours === 0) {
      hours = 12;
    }
    let newDate = new Date(parseInt(date));
    const d = {
      time: `${new Date(parseInt(date)).toLocaleString()}`,

      date: ` ${day}  ${Nday} ${month}, ${year}`,

      newDate,
    };

    return d;
  },
};
