import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import SideBar from "./sidebar/SideBar";
import "./index.css";
import Mainpage from "./mainpage/Mainpage";
import Popup from "./popups/Popup";
import store from "../../redux/store";

import {
  GET_ALL_REFERERS,
  GET_PAYMENTS,
  GET_THIRD_PARTY_BALANCE,
  GET_TRANSACTIONS,
  GET_USER,
  GET_USERS,
} from "../../redux/actions/action";
import { connect } from "react-redux";
const Dashboard = ({ data: { user, balances, users, transactions } }) => {
  const [state, setState] = useState({
    page: 1,
    page_to_show: "",
    //transaction_details: {},
    //user_details: {},
    payment_details: {},
  });
  const [userDetails, setUserDetails] = useState({});
  const [transactionDetails, setTransactionDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});

  useEffect(() => {
    store.dispatch(GET_USER());
    store.dispatch(GET_THIRD_PARTY_BALANCE());
    store.dispatch(GET_USERS());
    store.dispatch(GET_TRANSACTIONS());
    store.dispatch(GET_PAYMENTS());
    store.dispatch(GET_ALL_REFERERS());
  }, []);

  const modifyState = async (key, value) => {
    //console.log(state);
    return setState({ ...state, [key]: value });
  };
  return (
    <section className="admin-dashboard">
      <Header user={user} />
      <SideBar setState={modifyState} />
      <Mainpage
        setUserDetails={setUserDetails}
        setTransactionDetails={setTransactionDetails}
        setPaymentDetails={setPaymentDetails}
        setState={modifyState}
        state={state}
        balances={balances}
        users={users}
        transactions={transactions}
      />
      <Popup
        state={state}
        userDetails={userDetails}
        transactionDetails={transactionDetails}
        paymentDetails={paymentDetails}
        setState={modifyState}
      />
    </section>
  );
};

const mapStateToProps = ({ data }) => ({
  data,
});
export default connect(mapStateToProps, null)(Dashboard);
