/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./index.css";
import "./mobile.css";
import Utils from "../../../../components/utils";
const Transactions = ({
  setGlobalState,
  setTransactionDetails,
  transactions,
}) => {
  const [localState, setLocalState] = useState({
    filtered: [],
    currentItems: [],
    searchByUser: "",
    currentPage: 1,
    itemsPerPage: 5,
    searchById: "",
    searchByProduct: "",
  });
  const {
    currentPage,
    currentItems,
    searchByUser,
    itemsPerPage,
    filtered,
    searchById,
    searchByProduct,
  } = localState;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalItems = filtered.length;
  const pages = Math.ceil(totalItems / itemsPerPage);

  const modifyState = (key, value) => {
    setLocalState({ ...localState, [key]: value });
  };

  useEffect(() => {
    modifyState("filtered", transactions);

    return () => "";
  }, [transactions]);

  useEffect(() => {
    const items = filtered.slice(indexOfFirstItem, indexOfLastItem);

    modifyState("currentItems", items);

    return () => "";
  }, [filtered, currentPage]);

  const handlePagination = (pageNumber) => {
    modifyState("currentPage", pageNumber);

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!searchById && !searchByProduct) {
      if (searchByUser) {
        let fill = transactions.filter((transaction) =>
          transaction.user.firstName.toLowerCase().includes(searchByUser.toLowerCase()) || transaction.user.lastName.toLowerCase().includes(searchByUser.toLowerCase())
        );
        modifyState("filtered", fill);
      } else {
        modifyState("filtered", transactions);
      }
    }

    return () => "";
  }, [searchByUser]);

  useEffect(() => {
    if (!searchByUser && !searchByProduct) {
      if (searchById) {
        let fill = transactions.filter((transaction) =>
          transaction.id.includes(searchById)
        );
        modifyState("filtered", fill);
      } else {
        modifyState("filtered", transactions);
      }
    }

    return () => "";
  }, [searchById]);

  useEffect(() => {
    if (!searchByUser && !searchById) {
      if (searchByProduct) {
        let fill = transactions.filter((transaction) =>
          transaction?.product?.name ? transaction?.product?.name.includes(searchByProduct) : ''
        );
        modifyState("filtered", fill);
      } else {
        modifyState("filtered", transactions);
      }
    }

    return () => "";
  }, [searchByProduct]);

  const Open = (data) => {
    // setGlobalState("page_to_show", "useuuuur");
    setGlobalState("page_to_show", "transactiondetails");

    setTransactionDetails(data);

    Utils.showAdminPopUp();
  };

  const tableData = (transaction) => {
    return (
      <div
        key={transaction.id}
        className="table-data"
        onClick={() => Open(transaction)}
      >
        <ul>
          <li className="hide-on-mobile">{transaction?.id}</li>
          <li className="hide-on-mobile">{transaction?.user?.lastName + ' ' + transaction?.user?.firstName}</li>

          <li> {transaction.product.name}</li>
          <li>{Utils.formatCurrency(parseInt(transaction?.value))}</li>
          <li
            style={Utils.setTransactionStatusBackground(transaction)}
            className="status"
          >
            {transaction.status}
          </li>
        </ul>
      </div>
    );
  };

  const pagination = () => {
    return (
      <div className="pagination-box">
        <div className="">
          {currentPage > 1 && (
            <i
              className="fa fa-chevron-left"
              onClick={() => handlePagination(currentPage - 1)}
              disabled={currentPage === 1 ? true : false}
            ></i>
          )}
        </div>
        <div className="">
          <p className="btn-active">{currentPage}</p>
          <span className="pages"> of {pages}</span>
        </div>
        <div className="">
          {currentPage < pages && (
            <i
              className="fa fa-chevron-right"
              onClick={() => handlePagination(currentPage + 1)}
              disabled={currentPage === pages ? true : false}
            ></i>
          )}
        </div>
      </div>
    );
  };

  return (
    <section className="admin-transactions-page">
      <div className="header">
        <h3>Transactions</h3>
        <div>
          <input
            onChange={(e) => modifyState("searchByUser", e.target.value)}
            type="text"
            placeholder="search by user"
          />
          <input
            onChange={(e) => modifyState("searchById", e.target.value)}
            type="text"
            placeholder="search by id"
          />

          <input
            onChange={(e) => modifyState("searchByProduct", e.target.value)}
            type="text"
            placeholder="search by product"
          />
        </div>
      </div>
      <div className="table">
        <div className="table-label">
          <ul>
            <li className="hide-on-mobile">Transaction ID</li>
            <li className="hide-on-mobile">User</li>

            <li>Product</li>
            <li>Value</li>
            <li>Status</li>
          </ul>
        </div>
        {currentItems
          ? currentItems.map((transaction) => tableData(transaction))
          : null}
        {pagination()}
      </div>
    </section>
  );
};

export default Transactions;
