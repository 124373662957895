/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./login.auth.css";
import "./mobile.css";
import { connect } from "react-redux";
import store from "../../redux/store";
import { LOGIN_FAIL } from "../../redux/actions/action.types";
import { HANDLE_LOGIN } from "../../redux/actions/action";

const Index = ({ data }) => {
  const [state, setState] = useState({
    type: "password",
    email: "",
    password: "",
    message: {
      type: "",
      info: "",
    },
  });

  const TogglePassword = (value) => {
    setState({ ...state, type: value });
  };

  const { server_message } = data;

  useEffect(() => {
    if (server_message && server_message.type === LOGIN_FAIL) {
      setState({
        ...state,
        message: { type: "error", info: server_message.message },
      });
    }
  }, [server_message]);

  const handleLogin = () => {
    const { email, password } = state;
    if (!password || !email) {
      return setState({
        ...state,
        message: { type: "error", info: "Kindly fill in your details." },
      });
    }

    setState({ ...state, message: { type: "", info: "" } });

    store.dispatch(HANDLE_LOGIN({ email, password }));
  };

  return (
    <section className="login-page">
      <div>
        <h1>Login</h1>

        <form>
          {state.message.type === "error" ? (
            <span className="error-message">
              <h3>{state.message.info}</h3>
            </span>
          ) : null}

          {state.message.type === "success" ? (
            <span className="success-message">
              <h3>{state.message.info}</h3>
            </span>
          ) : null}
          <div>
            <i className="fa fa-envelope"></i>
            <input
              onChange={(e) => setState({ ...state, email: e.target.value })}
              type="email"
              placeholder="Email"
              value={state.email}
              required={true}
            />
          </div>
          <div>
            <i className="fa fa-lock"></i>
            <input
              onChange={(e) => setState({ ...state, password: e.target.value })}
              value={state.password}
              type={state.type}
              placeholder="Password"
              required={true}
            />

            {state.type === "text" ? (
              <i
                className="fa fa-eye-slash eye"
                onClick={() => TogglePassword("password")}
              />
            ) : (
              <i
                className="fa fa-eye eye"
                onClick={() => TogglePassword("text")}
              />
            )}
          </div>
        </form>

        <p onClick={() => handleLogin()}>Proceed</p>
      </div>
    </section>
  );
};

const mapStateToProps = ({ data }) => ({
  data,
});

export default connect(mapStateToProps, null)(Index);
