import React from 'react';
import Balance from './balance/Balance';
import './index.css';
import RecentUsers from './lastloggedusers/RecentUsers';
import TotalPayments from './totalPayments';
import TotalUsers from './totalUsers/TotalUsers';
const Overview = ({ setState, balances, users }) => {
  const payments = [];
  const balance = [
    {
      name: 'ATG Wallet Balance',
      amount: 0,
    },
    {
      name: 'VTpass Wallet Balance',
      amount: balances?.vtpassBalance ? balances.vtpassBalance.balance : 0,
    },
    {
      name: 'Flutterwave Available Balance',
      amount:
        balances && balances.flutterwaveBalance
          ? balances.flutterwaveBalance.available_balance
          : 0,
    },
    {
      name: 'Flutterwave Ledger Balance',
      amount:
        balances && balances.flutterwaveBalance
          ? balances.flutterwaveBalance.ledger_balance
          : 0,
    },
  ];

  return (
    <section className='admin-overview'>
      <h2>Overview</h2>
      <div className='grid-wrapper'>
        <TotalUsers Users={users} />
        <TotalPayments Payments={payments} />
        {balance.map((x, i) => (
          <Balance key={i} Balance={x.amount} name={x.name} />
        ))}
      </div>

      <RecentUsers setState={setState} Users={users} />
    </section>
  );
};

export default Overview;
