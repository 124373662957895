/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import utils from "../../../../../components/utils";
import { ADD_REFERER } from "../../../../../redux/actions/action.types";
import "./index.css";
import "./mobile.css";
import store from "../../../../../redux/store";
import { CREATE_REFERER } from "../../../../../redux/actions/action";

const Add = ({ server_message }) => {
  const [state, setState] = useState({
    message: {
      info: "",
      type: "",
    },
    name: "",
    description: "",
  });

  useEffect(() => {
    if (server_message && server_message.type === ADD_REFERER) {
      setState({
        ...state,
        message: { info: server_message.message, type: "success" },
      });

      setTimeout(() => {
        utils.closeAdminPopUp();
      }, 1000 * 2);
    }
  }, [server_message]);

  const createReferer = () => {
    const { name, description } = state;
    if (!name || !description)
      return setState({
        ...state,
        message: { info: "kindly entered all fields.", type: "error" },
      });

    setState({ ...state, message: { info: "", type: "" } });

    store.dispatch(CREATE_REFERER({ name, description }));
  };
  return (
    <div className="add-referrer slowly-show-popups">
      <h4>{state.message.info}</h4>
      <form>
        <div className="">
          <input
            type="text"
            placeholder="Name"
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
        </div>

        <div className="">
          <input
            type="text"
            placeholder="Description"
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
          />
        </div>
      </form>

      <div className="action-btns">
        <p onClick={() => utils.closeAdminPopUp()}>Cancel</p>
        <p onClick={() => createReferer()}>Add</p>
      </div>
    </div>
  );
};

const mapStateToProps = ({ data: { server_message } }) => ({
  server_message,
});

export default connect(mapStateToProps, null)(Add);
