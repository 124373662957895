import React from "react";
import "./index.css";

const TotalUsers = ({ Users }) => {
  return (
    <div className="total-number-of-users">
      <div className="value">
        <p>{Users ? Users.length : 0}</p>
      </div>
      <p>
        Total Number <br></br>Of Users
      </p>
    </div>
  );
};

export default TotalUsers;
