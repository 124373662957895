export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOADING = 'LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_LOADED = 'USER_LOADED';
export const THIRD_PARTY_BALANCE = 'THIRD_PARTY_BALANCE';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS';
export const USER_ADMIN_ACTION = 'USER_ADMIN_ACTION';
export const LOAD_PAYMENTS = 'LOAD_PAYMENTS';
export const LOAD_REFERERS = 'LOAD_REFERERS';
export const ADD_REFERER = 'ADD_REFERER';
export const BAD_REQUEST = 'BAD_REQUEST';
export const REVERSE_TRANSACTION = 'REVERSE_TRANSACTION';
