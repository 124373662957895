import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/authentication/login.auth';
import Dashboard from './pages/dashboard/index.dashboard';
import { RedirectHome, ProtectAdmin } from './protect';
import { connect } from 'react-redux';
// import { useEffect } from 'react';
// import utils from './components/utils';
function App({ user, isAuthenticated, token, is_loading }) {
  // useEffect(() => {
  //   if (is_loading) {
  //     utils.showPopUp();
  //   } else if (!is_loading) {
  //     utils.closePopUp();
  //   }
  // }, [is_loading]);

  return (
    <Routes>
      <Route
        exact
        path='/auth/login'
        element={
          <RedirectHome
            user={user}
            isAuthenticated={isAuthenticated}
            token={token}
            Component={Login}
          />
        }
      />
      <Route
        path='/'
        element={
          <ProtectAdmin
            user={user}
            isAuthenticated={isAuthenticated}
            token={token}
            Component={Dashboard}
          />
        }
      />
    </Routes>
  );
}

const mapStateToProps = ({
  data: { user, isAuthenticated, token, is_loading },
}) => ({
  user,
  isAuthenticated,
  token,
  is_loading,
});
export default connect(mapStateToProps, null)(App);
