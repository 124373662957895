import { AUTH_ERROR } from './action.types';

export const FORMAT_ERROR_MESSAGE = (type, error) => {
  const msg = {
    type,
    message:
      error?.response && error?.response?.data?.data
        ? error.response.data.data.message
        : 'oops! a problem occurred.',
  };

  return msg;
};

export const FORMAT_SUCCESS_MESSAGE = (type, message) => {
  const msg = {
    type,
    message: message || 'successful.',
  };

  return msg;
};

export const HANDLE_ERROR = async (error, dispatch, type = '') => {
  if (error.response && error.response.status === 403) {
    /*Log the user out */
    const msg = {
      type: AUTH_ERROR,
      message: 'Your session has expired, please login again.',
    };

    return await dispatch({
      type: AUTH_ERROR,
      payload: msg,
    });
  }

  if (error.response && error.response.status === 401) {
    /*Log the user out */
    const msg = {
      type: AUTH_ERROR,
      message: FORMAT_ERROR_MESSAGE(AUTH_ERROR, error),
    };
    return await dispatch({
      type: AUTH_ERROR,
      payload: msg,
    });
  }

  return await dispatch({
    type,
    payload: FORMAT_ERROR_MESSAGE(type, error.response.data.data.message),
  });
};

export const pullResponse = (response) => {
  return response.data;
};
