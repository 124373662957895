import {
  ADD_REFERER,
  AUTH_ERROR,
  BAD_REQUEST,
  LOADING,
  LOAD_PAYMENTS,
  LOAD_REFERERS,
  LOAD_TRANSACTIONS,
  LOAD_USERS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REVERSE_TRANSACTION,
  THIRD_PARTY_BALANCE,
  USER_ADMIN_ACTION,
  USER_LOADED,
} from '../actions/action.types';

const initial_state = {
  token: localStorage.getItem('token'),
  user: null,
  transactions: [],
  isAuthenticated: false,
  is_loading: false,
  server_message: {
    type: '',
    message: '',
  },
  payments: [],
  users: [],
  refresh_token: localStorage.getItem('refresh_token'),
  products: [],
  balances: null,
  referers: [],
};

const reduxStore = (state = initial_state, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        is_loading: action.payload,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.accessToken);
      return {
        ...state,
        token: action.payload.accessToken,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case LOGIN_FAIL:
    case AUTH_ERROR:
      localStorage.removeItem('token');
      return {
        ...state,
        server_message: action.payload,
        isAuthenticated: false,
        token: null,
      };

    case USER_LOADED:
      return {
        ...state,
        user: action.payload,
      };
    case THIRD_PARTY_BALANCE:
      return {
        ...state,
        balances: action.payload,
      };

    case LOAD_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case LOAD_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    case USER_ADMIN_ACTION:
    case ADD_REFERER:
      return {
        ...state,
        server_message: action.payload,
      };
    case LOAD_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
      };

    case LOAD_REFERERS:
      return {
        ...state,
        referers: action.payload,
      };
    case REVERSE_TRANSACTION:
    case BAD_REQUEST:
      return {
        ...state,
        server_message: action.payload,
      };

    default:
      return state;
  }
};

export default reduxStore;
