import React from 'react';
import utils from '../../../../../components/utils';
import { HANDLE_REVERSE_TRANSACTION } from '../../../../../redux/actions/action';
import './index.css';
import './mobile.css';
import store from '../../../../../redux/store';

const Transaction = ({ Tdetails }) => {
  const reverseT = () => {
    let data = {
      transactionId: Tdetails.id,
    };
    store.dispatch(HANDLE_REVERSE_TRANSACTION(data));
    // Utils.closeAdminPopUp()
  };

  return (
    <div className='admin-tdetails slowly-show-popups'>
      <div className='right'>
        {/* {console.log(Tdetails)} */}
        <p>Time: {utils.formatDate(Tdetails.createdAt).time}</p>
        <p>Date: {utils.formatDate(Tdetails.createdAt).date}</p>

        {Tdetails.product.name === 'airtime' ||
        Tdetails.product.name === 'data' ? (
          <p>Phone No: {Tdetails.product.metadata.phoneNumber}</p>
        ) : Tdetails.product.name === 'electricity-bills' ? (
          <p>
            Meter No:{' '}
            {Tdetails.product.metadata.meterNumber
              ? Tdetails.product.metadata.meterNumber
              : null}
          </p>
        ) : Tdetails.product === 'cabletv' ? (
          <p>
            Smart Card Number No: {Tdetails.product.metadata.smartCardNumber}
          </p>
        ) : null}
        <p>
          Balance Before:{' '}
          {utils.formatCurrency(parseFloat(Tdetails.balanceBefore))}
        </p>
        <p>
          Balance After:{' '}
          {utils.formatCurrency(parseFloat(Tdetails.balanceAfter))}
        </p>
        {/* {Tdetails.pplan ? <p>Plan: {Tdetails.plan}</p> : null} */}
        {/* {Tdetails.customerName ? (
          <p>Customer: {Tdetails.customerName}</p>
        ) : null} */}
        {/* {Tdetails.account_id ? <p>Account ID: {Tdetails.account_id}</p> : null} */}
        {/* {Tdetails.response_msg.msg ? (
          <p>Status: {Tdetails.response_msg["msg"]}</p>
        ) : null} */}
        {/* {Tdetails.product !== "Coupon" && Tdetails.response_msg.data.msg ? (
          <p>
            R-STATUS:{" "}
            {Tdetails.response_msg.data.msg.response_description ||
              Tdetails.response_msg.data.msg}
          </p>
        ) : null} */}
        {Tdetails.amountCharged ? (
          <p>
            Amount-Charged:{' '}
            {utils.formatCurrency(parseFloat(Tdetails.amountCharged))}
          </p>
        ) : null}
        {/* {Tdetails.product !== "Coupon" && Tdetails.response_msg.data.token ? (
          <p>Token: {Tdetails.response_msg.data.token}</p>
        ) : Tdetails.product !== "Coupon" &&
          Tdetails.response_msg.data.purchased_code ? (
          <p>Token: {Tdetails.response_msg.data.purchased_code}</p>
        ) : null}
        {Tdetails.product !== "Coupon" &&
          Tdetails.response_msg.data.exchangeReference ? (
          <p>E-REF: {Tdetails.response_msg.data.exchangeReference}</p>
        ) : null}
        {Tdetails.product !== "Coupon" && Tdetails.response_msg.data.units ? (
          <p>Units: {Tdetails.response_msg.data.units}</p>
        ) : Tdetails.product !== "Coupon" &&
          Tdetails.response_msg.data.Units ? (
          <p>Units: {Tdetails.response_msg.data.Units}</p>
        ) : Tdetails.product !== "Coupon" &&
          Tdetails.response_msg.data.PurchasedUnits ? (
          <p>Units: {Tdetails.response_msg.data.PurchasedUnits}</p>
        ) : null} */}
      </div>

      <div className='left'>
        <p onClick={() => utils.closeAdminPopUp()}>Close</p>

        {Tdetails.status === 'failed' || Tdetails.status === 'pending' ? (
          <p onClick={() => reverseT()}>Reverse</p>
        ) : null}
      </div>
    </div>
  );
};

export default Transaction;
