import React from 'react';
import utils from '../../../../../components/utils';
import './index.css';
import './mobile.css';
import store from '../../../../../redux/store';
import { USER_ADMIN_ACTIONS } from '../../../../../redux/actions/action';
import { USER_ADMIN_ACTION } from '../../../../../redux/actions/action.types';
const User = ({ userDetails, server_message }) => {
  const performActions = (id, type) => {
    store.dispatch(
      USER_ADMIN_ACTIONS({ user_id: id, type, reasonForBlock: 'ADMIN Block' })
    );
  };

  return (
    <div className='userdetails slowly-show-popups'>
      <div className='right'>
        <h4>
          {server_message.type === USER_ADMIN_ACTION
            ? server_message.message
            : null}
        </h4>

        <p>Blocked: {userDetails.isBlocked ? 'True' : 'False'}</p>
        <p>Phone: {userDetails.phoneNumber}</p>
        <p>
          Account Created On: {utils.formatDate(userDetails.createdAt).date}
        </p>
        {userDetails.status === 'BLOCKED' ? (
          <p>Reason for Block: {userDetails.reasonForBlock}</p>
        ) : null}
      </div>

      <div className='left'>
        <p onClick={() => utils.closeAdminPopUp()}>Close</p>
        {userDetails.status === 'BLOCKED' ? (
          <p onClick={() => performActions(userDetails._id, 'un-block')}>
            UnBlock
          </p>
        ) : (
          <p onClick={() => performActions(userDetails._id, 'block')}>Block</p>
        )}
      </div>
    </div>
  );
};

export default User;
