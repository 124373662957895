import React from "react";
import Overview from "./overview/Overview";
import Payments from "./payments/Payments";
import Referrers from "./referrer/Referrers";
import Transactions from "./transactions/Transactions";
import Users from "./users/Users";

const Mainpage = ({
  state: { page },
  setState,
  setUserDetails,
  setTransactionDetails,
  setPaymentDetails,
  balances,
  users,
  transactions,
}) => {
  return (
    <section className="admin-main-page">
      {page === 1 ? (
        <Overview setState={setState} balances={balances} users={users} />
      ) : page === 2 ? (
        <Users
          setGlobalState={setState}
          setUserDetails={setUserDetails}
          users={users}
        />
      ) : page === 3 ? (
        <Transactions
          setTransactionDetails={setTransactionDetails}
          setGlobalState={setState}
          transactions={transactions}
        />
      ) : page === 4 ? (
        <Payments
          setPaymentDetails={setPaymentDetails}
          setGlobalState={setState}
        />
      ) : page === 5 ? (
        <Referrers setGlobalState={setState} />
      ) : null}
    </section>
  );
};

export default Mainpage;
