import React from 'react';
import utils from '../../../../../components/utils';
import './index.css';
import './mobile.css';
const Payment = ({ paymentDetails }) => {
  return (
    <div className='payment-details slowly-show-popups'>
      <div className='right'>
        <p>Payment ID: {paymentDetails?.id}</p>
        <p>Payment REF: {paymentDetails?.paymentRef}</p>

        <p>Payment Time: {utils.formatDate(paymentDetails?.createdAt).time}</p>
        <p>Payment Date: {utils.formatDate(paymentDetails?.createdAt).date}</p>
        <p>
          User:{' '}
          {paymentDetails?.user?.lastName +
            ' ' +
            paymentDetails?.user?.firstName}
        </p>
        <p>User ID: {paymentDetails?.id}</p>
      </div>

      <div className='left'>
        <p onClick={() => utils.closeAdminPopUp()}>Close</p>
      </div>
    </div>
  );
};

export default Payment;
