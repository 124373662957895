/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './index.css';
import './mobile.css';
// import { payments } from "../../../../components/test_data";
import { connect } from 'react-redux';
import Utils from '../../../../components/utils';
const Payments = ({ setGlobalState, setPaymentDetails, payments }) => {
  const [localState, setLocalState] = useState({
    filtered: [],
    searchByUser: '',
    searchByRef: '',
    searchById: '',
  });

  const { filtered, searchByRef, searchByUser, searchById } = localState;
  const modifyState = (key, value) => {
    setLocalState({ ...localState, [key]: value });
  };

  useEffect(() => {
    modifyState('filtered', payments);
  }, [payments]);

  useEffect(() => {
    if (!searchByRef && !searchById) {
      if (searchByUser) {
        let fill = payments.filter(
          (payment) =>
            payment.user.firstName
              .toLowerCase()
              .includes(searchByUser.toLowerCase()) ||
            payment.user.lastName
              .toLowerCase()
              .includes(searchByUser.toLowerCase()) ||
            payment.user.email
              .toLowerCase()
              .includes(searchByUser.toLowerCase())
        );

        modifyState('filtered', fill);
      } else {
        modifyState('filtered', payments);
      }
    }
  }, [searchByUser]);

  useEffect(() => {
    if (!searchByUser && !searchById) {
      if (searchByRef) {
        let fill = payments.filter((payment) =>
          payment.paymentRef.toLowerCase().includes(searchByRef.toLowerCase())
        );
        modifyState('filtered', fill);
      } else {
        modifyState('filtered', payments);
      }
    }
  }, [searchByRef]);

  useEffect(() => {
    if (!searchByUser && !searchByRef) {
      if (searchById) {
        let fill = payments.filter((payment) =>
          payment.id.toLowerCase().includes(searchById.toLowerCase())
        );
        modifyState('filtered', fill);
      } else {
        modifyState('filtered', payments);
      }
    }
  }, [searchById]);

  const Open = (data) => {
    setGlobalState('page_to_show', 'paymentdetails');
    setPaymentDetails(data);
    //setGlobalState("payment_details", data);
    Utils.showAdminPopUp();
  };

  const tableData = (payment) => {
    return (
      <div
        key={payment?.id}
        className='table-data'
        onClick={() => Open(payment)}
      >
        <ul>
          <li>{payment?.id}</li>
          <li>{payment?.paymentRef}</li>
          <li>{payment?.user?.lastName + ' ' + payment?.user?.firstName}</li>

          <li>{Utils.formatCurrency(parseInt(payment.amount))}</li>
          <li
            style={Utils.setPaymentStatusBackground({
              status: payment.paymentStatus,
            })}
            className='status'
          >
            {payment.paymentStatus}
          </li>
        </ul>
      </div>
    );
  };
  return (
    <section className='admin-payment-view'>
      <div className='header'>
        <h3>Payments</h3>
        <div>
          <input
            onChange={(e) => modifyState('searchByUser', e.target.value)}
            type='text'
            placeholder='search by user'
          />
          <input
            onChange={(e) => modifyState('searchByRef', e.target.value)}
            type='text'
            placeholder='search by Ref'
          />
          <input
            onChange={(e) => modifyState('searchById', e.target.value)}
            type='text'
            placeholder='search by ID'
          />
        </div>
      </div>
      <div className='table'>
        <div className='table-label'>
          <ul>
            <li>Payment ID</li>
            <li>Payment Ref</li>
            <li>User</li>
            <li>Amount</li>
            <li>Status</li>
          </ul>
        </div>
        {filtered ? filtered.map((payment) => tableData(payment)) : null}
      </div>
    </section>
  );
};

const MapStateToProps = ({ data: { payments } }) => ({
  payments,
});

export default connect(MapStateToProps, null)(Payments);
