import axios from "axios";
import qs from "querystring";
// Declare a Map to store the identification and cancellation functions for each request

const pending = new Map();
/**
 * Add Request
 * @param {Object} config
 */
const addPending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join("&");
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pending.has(url)) {
        // If the current request does not exist in pending, add it
        pending.set(url, cancel);
      }
    });
};
/**
 * Remove Request
 * @param {Object} config
 */
const removePending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join("&");
  if (pending.has(url)) {
    // If the current request identity exists in pending, you need to cancel the current request and remove it
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
};
/**
 * Empty requests in pending (called on route jumps)
 */
export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
};

axios.interceptors.request.use(
  (config) => {
    removePending(config); // Check previous requests to cancel before the request starts
    addPending(config); // Add current request to pending
    // other code before request
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    removePending(response); // Remove this request at the end of the request
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      //   console.log('repeated request: ' + error.message)
    } else {
      // handle error code
    }
    return Promise.reject(error);
  }
);
