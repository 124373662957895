import React from "react";
import Utils from "../../../../../components/utils";
import "./index.css";

const Balance = ({ Balance, name }) => {
  return (
    <div className="admin-wallet-balance">
      <div className="value">
        <p>
          <span>NGN</span>
          <br></br>
          {Utils.currency(Balance !== null ? parseInt(Balance) : 0)}
        </p>
      </div>
      <p>{name}</p>
    </div>
  );
};

export default Balance;
