import React from "react";
import "./SideBar.css";
import "./mobile.css";

const tags = [
  {
    div: "overview side-bar-active",
    i: "fa fa-home",
    description: "Overview",
  },
  {
    div: "user",
    i: "fa fa-user",
    description: "Users",
  },
  {
    div: "transactions-div",
    i: "fa fa-exchange-alt",
    description: "Transactions",
  },
  {
    div: "payments",
    i: "fa fa-money-bill-wave",
    description: "Payments",
  },
  {
    div: "side-buttons",
    i: "fa fa-user-friends",
    description: "Referrers",
  },
];

const SideBar = ({ setState }) => {
  const hideDescription = (e) => {
    const description = document.getElementsByClassName("description");
    const mainpage = document.getElementsByClassName("admin-main-page");

    const chev_right = e.currentTarget.children[1];
    const chev_left = e.currentTarget.children[0];

    if (chev_left.classList.contains("show-chev")) {
      chev_left.classList.remove("show-chev");
      chev_right.style.display = "block";
      e.currentTarget.parentElement.classList.remove("maximize-sidebar");
      mainpage[0].classList.remove("maximize-mainpage");

      for (let i = 0; i < description.length; i++) {
        description[i].classList.remove("slowly-show-description");
      }
    } else {
      chev_left.classList.add("show-chev");
      chev_right.style.display = "none";
      e.currentTarget.parentElement.classList.add("maximize-sidebar");
      //console.log(mainpage);
      mainpage[0].classList.add("maximize-mainpage");

      //Loop through each data
      for (let i = 0; i < description.length; i++) {
        description[i].classList.add("slowly-show-description");
      }
    }
  };

  const updatePage = ({ p, e }) => {
    //setPage(p);
    setState("page", p);
    const sidebar = document.getElementsByClassName("admin-sidebar");
    const description = document.getElementsByClassName("description");
    const left = document.getElementsByClassName("fa-chevron-circle-left");
    const right = document.getElementsByClassName("fa-chevron-circle-right");
    const mainpage = document.getElementsByClassName("admin-main-page");

    if (sidebar[0].classList.contains("show-sidebar")) {
      sidebar[0].classList.remove("show-sidebar");
      // for ( let i = 0; i < description.length; i++ ){
      //     description[i].classList.remove('slowly-show-description')

      // }
    }

    if (sidebar[0].classList.contains("maximize-sidebar")) {
      for (let i = 0; i < description.length; i++) {
        description[i].classList.remove("slowly-show-description");
      }
      left[0].classList.remove("show-chev");
      right[0].style.display = "block";
      sidebar[0].classList.remove("maximize-sidebar");
      mainpage[0].classList.remove("maximize-mainpage");
    }

    //For Direct Children
    const childrenArray = e.currentTarget.parentElement.children;

    for (let i = 0; i < childrenArray.length; i++) {
      childrenArray[i].classList.remove("side-bar-active");
    }
    // ////For Element in the footer Section
    // const footer =
    //   document.getElementsByClassName("sidebar-footer")[0].children;
    // for (let i = 0; i < footer.length; i++) {
    //   footer[i].classList.remove("side-bar-active");
    // }

    // ///Check the Grand Parent Section
    // const grandParent = e.currentTarget.parentElement.parentElement.children;

    // for (let i = 0; i < grandParent.length; i++) {
    //   grandParent[i].classList.remove("side-bar-active");
    // }

    e.currentTarget.classList.add("side-bar-active");
  };

  return (
    <section className="admin-sidebar">
      <div
        className="direction hide-on-mobile"
        onClick={(e) => hideDescription(e)}
      >
        <i className="fa fa-chevron-circle-left"></i>
        <i className="fa fa-chevron-circle-right"></i>
      </div>

      {tags.map((x, i) => (
        <div
          key={i}
          className={x.div}
          onClick={(e) => updatePage({ e, p: i + 1 })}
        >
          <i className={x.i}></i>
          <p className="description">{x.description}</p>
        </div>
      ))}
    </section>
  );
};

export default SideBar;
