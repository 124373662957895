import axios from 'axios';
import store from '../store';
import '../../request.settings';
import {
  ADD_REFERER,
  AUTH_ERROR,
  LOADING,
  LOAD_PAYMENTS,
  LOAD_REFERERS,
  LOAD_TRANSACTIONS,
  LOAD_USERS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  THIRD_PARTY_BALANCE,
  USER_ADMIN_ACTION,
  USER_LOADED,
  REVERSE_TRANSACTION,
} from './action.types';
import {
  FORMAT_ERROR_MESSAGE,
  FORMAT_SUCCESS_MESSAGE,
  HANDLE_ERROR,
  pullResponse,
} from './actions.utils';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// const baseUrl ='https://staging.creditsync.com.ng/api/v2'
const baseUrl = 'https://api-v2.creditsync.com.ng/api/v2';

const tokenConfig = () => {
  const token = localStorage.getItem('token');

  if (token) config.headers['Authorization'] = `Bearer ${token}`;

  return config;
};

export const CREATE_REFERER = (payload) => async (dispatch) => {
  try {
    await store.dispatch(IS_LOADING(true));

    const body = JSON.stringify(payload);
    await axios.post(baseUrl + '/misc/create_referer', body, tokenConfig());

    await dispatch({
      type: ADD_REFERER,
      payload: FORMAT_SUCCESS_MESSAGE(
        ADD_REFERER,
        'Referer added successfully.'
      ),
    });

    await store.dispatch(GET_ALL_REFERERS());
  } catch (error) {
    await HANDLE_ERROR(error, dispatch, AUTH_ERROR);

    return await store.dispatch(IS_LOADING());
  }
};

export const USER_ADMIN_ACTIONS = (payload) => async (dispatch) => {
  try {
    await store.dispatch(IS_LOADING(true));

    const { user_id, type, reasonForBlock } = payload;

    const response = await axios.put(
      baseUrl + `/users/${user_id}/${type}`,
      { reasonForBlock },
      tokenConfig()
    );

    await dispatch({
      type: USER_ADMIN_ACTION,
      payload: FORMAT_SUCCESS_MESSAGE(
        USER_ADMIN_ACTION,
        pullResponse(response.data).message
      ),
    });

    await store.dispatch(GET_USERS());
  } catch (error) {
    await HANDLE_ERROR(error, dispatch, USER_ADMIN_ACTION);

    return await store.dispatch(IS_LOADING());
  }
};

export const GET_ALL_REFERERS = () => async (dispatch) => {
  try {
    const response = await axios.get(
      baseUrl + '/misc/all_referer',
      tokenConfig()
    );

    await dispatch({
      type: LOAD_REFERERS,
      payload: response.data.message,
    });
  } catch (error) {
    await HANDLE_ERROR(error, dispatch);

    return await store.dispatch(IS_LOADING());
  }
};

export const GET_PAYMENTS = () => async (dispatch) => {
  try {
    const response = await axios.get(
      baseUrl + '/payments?limit=10000',
      tokenConfig()
    );

    await dispatch({
      type: LOAD_PAYMENTS,
      payload: pullResponse(response.data).data,
    });
  } catch (error) {
    await HANDLE_ERROR(error, dispatch);

    return await store.dispatch(IS_LOADING());
  }
};

export const GET_TRANSACTIONS = () => async (dispatch) => {
  try {
    const response = await axios.get(
      baseUrl + '/transactions?limit=10000',
      tokenConfig()
    );

    await dispatch({
      type: LOAD_TRANSACTIONS,
      payload: pullResponse(response.data).data,
    });
  } catch (error) {
    await HANDLE_ERROR(error, dispatch);

    return await store.dispatch(IS_LOADING());
  }
};

export const HANDLE_REVERSE_TRANSACTION = (payload) => async (dispatch) => {
  try {
    await store.dispatch(IS_LOADING(true));

    const { transactionId } = payload;

    axios.defaults.headers.common['Authorization'] =
      tokenConfig().headers['Authorization'];

    const response = await axios.put(
      baseUrl + `/transactions/${transactionId}/reverse`
    );

    await dispatch({
      type: REVERSE_TRANSACTION,
      payload: FORMAT_SUCCESS_MESSAGE(
        REVERSE_TRANSACTION,
        pullResponse(response.data).message
      ),
    });

    await store.dispatch(GET_TRANSACTIONS());
  } catch (error) {
    await HANDLE_ERROR(error, dispatch, REVERSE_TRANSACTION);
  }
};

export const GET_USERS = () => async (dispatch) => {
  try {
    const response = await axios.get(
      baseUrl + '/users?limit=10000',
      tokenConfig()
    );

    await dispatch({
      type: LOAD_USERS,
      payload: pullResponse(response.data).data,
    });
  } catch (error) {
    await HANDLE_ERROR(error, dispatch, AUTH_ERROR);

    return await store.dispatch(IS_LOADING());
  }
};

export const GET_THIRD_PARTY_BALANCE = () => async (dispatch) => {
  try {
    let response = await axios.get(
      baseUrl + '/misc/third-party-balances',
      tokenConfig()
    );

    await dispatch({
      type: THIRD_PARTY_BALANCE,
      payload: pullResponse(response.data).data,
    });

    await store.dispatch(IS_LOADING(true));
  } catch (error) {
    await HANDLE_ERROR(error, dispatch);

    return await store.dispatch(IS_LOADING());
  }
};

export const GET_USER = () => async (dispatch) => {
  try {
    await store.dispatch(IS_LOADING(true));

    let response = await axios.get(baseUrl + '/users/me', tokenConfig());

    response = response.data;

    await dispatch({
      type: USER_LOADED,
      payload: pullResponse(response).data,
    });
    await store.dispatch(IS_LOADING());
  } catch (error) {
    await HANDLE_ERROR(error, dispatch);

    return await store.dispatch(IS_LOADING());
  }
};

export const IS_LOADING =
  (choice = false) =>
  (dispatch) => {
    dispatch({
      type: LOADING,
      payload: choice,
    });
  };

export const HANDLE_LOGIN = (payload) => async (dispatch) => {
  try {
    await store.dispatch(IS_LOADING(true));

    const body = JSON.stringify(payload);

    let response = await axios.post(baseUrl + '/auth/login', body, config);

    response = response.data;

    await dispatch({
      type: LOGIN_SUCCESS,
      payload: pullResponse(response).data,
    });

    await store.dispatch(IS_LOADING());
  } catch (error) {
    await dispatch({
      type: LOGIN_FAIL,
      payload: FORMAT_ERROR_MESSAGE(LOGIN_FAIL, error),
    });

    await store.dispatch(IS_LOADING());
  }
};
