/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Utils from '../../../../components/utils';
import './index.css';
import './mobile.css';

const Users = ({ setGlobalState, setUserDetails, users }) => {
  const [localState, setLocalState] = useState({
    filtered: [],
    searchbar: '',
  });

  const modifyState = (key, value) => {
    setLocalState({ ...localState, [key]: value });
  };

  const setStatusBackground = (user) => {
    if (user.status === 'ACTIVE') {
      return { background: '#5df888' };
    } else if (user.status === 'BLOCKED') {
      return { background: '#f85d5d' };
    }
  };

  useEffect(() => {
    modifyState('filtered', users);

    return () => modifyState('filtered', []);
  }, [users]);

  useEffect(() => {
    if (localState.searchbar) {
      let fill = users.filter(
        (user) =>
          user.email
            .toLowerCase()
            .includes(localState.searchbar.toLowerCase()) ||
          user.firstName
            .toLowerCase()
            .includes(localState.searchbar.toLowerCase()) ||
          user.lastName
            .toLowerCase()
            .includes(localState.searchbar.toLowerCase())
      );
      modifyState('filtered', fill);
      //console.log(fill);
    } else {
      modifyState('filtered', users);
    }
    return () => '';
  }, [localState.searchbar]);

  const tableData = (user) => {
    return (
      <div key={user.id} className='table-data' onClick={() => Open(user)}>
        <ul>
          <li>{user.isAdmin ? 'admin' : 'user'}</li>
          <li>{user.firstName + ' ' + user.lastName}</li>
          <li className='hide-on-mobile'> {user.email}</li>
          <li>
            {Utils.formatCurrency(
              user.walletBalance ? parseInt(user.walletBalance) : 0
            )}
          </li>
          <li style={setStatusBackground(user)} className='status'>
            {user.status}
          </li>
        </ul>
      </div>
    );
  };

  const Open = (data) => {
    setGlobalState('page_to_show', 'user');

    setUserDetails(data);

    //setGlobalState("user_details", data);

    Utils.showAdminPopUp();
  };

  return (
    <section className='admin-users'>
      <div className='header'>
        <h3>Users</h3>
        <input
          onChange={(e) => modifyState('searchbar', e.target.value)}
          type='text'
          placeholder='Search'
        />
      </div>
      <div className='table'>
        <div className='table-label'>
          <ul>
            <li>Role</li>
            <li>Name</li>
            <li className='hide-on-mobile'>Email</li>
            <li>Wallet Balance</li>

            <li>Status</li>
          </ul>
        </div>
        {localState.filtered
          ? localState.filtered.map((user) => tableData(user))
          : null}
      </div>
    </section>
  );
};

export default Users;
