import React from "react";
import "./index.css";
import "./mobile.css";
const Index = ({ user }) => {
  const handleSideBar = () => {
    const description = document.getElementsByClassName("description");
    const sidebar = document.getElementsByClassName("admin-sidebar");
    if (sidebar[0].classList.contains("show-sidebar")) {
      sidebar[0].classList.remove("show-sidebar");
      for (let i = 0; i < description.length; i++) {
        description[i].classList.remove("slowly-show-description");
      }
    } else {
      sidebar[0].classList.add("show-sidebar");
      for (let i = 0; i < description.length; i++) {
        description[i].classList.add("slowly-show-description");
      }
    }
  };
  return (
    <header className="admin-header">
      <div className="left">
        <img
          className="site-logo"
          src="https://creditsync.com.ng/images/logo-website.png"
          alt="creditsync"
        />
      </div>
      <div className="header-right">
        <p className="username">{user ? user.name : "John Doe"}</p>
        <i onClick={() => handleSideBar()} className="fa fa-bars"></i>
      </div>
    </header>
  );
};

export default Index;
