import React from "react";
import "./index.css";
import { connect } from "react-redux";

const TotalPayments = ({ payments }) => {
  return (
    <div className="total-number-of-transactions-admin">
      <div className="value">
        <p>{payments ? payments.length : 0}</p>
      </div>
      <p>
        Total Number <br></br>Of Payments
      </p>
    </div>
  );
};

const mapStateToProps = ({ data: { payments } }) => ({
  payments,
});

export default connect(mapStateToProps, null)(TotalPayments);
